import React, { Component, Fragment } from 'react'

export default class header extends Component {

    goHome = ()=>{
        window.location.href = '/';
    }

    render() {
        return (
            <Fragment>
                <div id="top-bar" className="full align-self-bottom">
                    <div className="grid-container">
                        <div className="grid-x grid-padding-y">
                            <div className="small-12 medium-8 large-8 cell text-center medium-text-left">
                                <img style={{cursor: 'pointer'}} width="250" src="https://aroundcabo.s3-us-west-2.amazonaws.com/booking/logo.png" alt="" onClick={this.goHome}/>
                            </div>
                            <div className="small-12 medium-4 large-4 cell align-self-bottom text-center medium-text-right">
                                <p><a href="tel:011(52)61243553150">BOOK NOW:  +52 (624) 3553 150</a> <br className="show-for-small-only" />  <a href="mailto:reservations@aroundcabo.com">reservations@aroundcabo.com</a> </p>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
