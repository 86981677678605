import React, { Component } from 'react';
import { ApiGetTransports } from './../../services/transport';
import { ApiCreateReservation, ApiRatesReservation, ApiGetRateByLocation } from './../../services/booking'
import { storageJson, storageVal, removeStorageVal } from './../../services/storage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import Swal from 'sweetalert2';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';

import Header from './../utils/header';
import Transport from './../utils/transport';
import Details from './../utils/details';
import Zones from './../../../src/places.json';
import Footer from './../utils/footer';
import { unstable_renderSubtreeIntoContainer } from 'react-dom';

const DEFAULT_LABEL = '#000000';
const ERROR_LABEL = '#c0392b';

const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
};

const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
};

const formatGroupLabel = data => (
    <div style={groupStyles}>
        <span>{data.label}</span>
        <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
);

const nowDate = new Date();

export default class index extends Component {
    // eslint-disable-next-line no-useless-constructor
    constructor() {
        super();

        this.state = {
            airlines: Zones.airlines,
            startDateArrival: nowDate.setDate(nowDate.getDate() + 1),
            startDateDeparture: nowDate.setDate(nowDate.getDate() + 1),
            transportSelected: {},
            valid: true,
            acceptPolices: false,
            rateSelected: {
                location: '',
                destination: '',
                trip_type: 'Round Trip'
            },
            formError: false,
            new: {
                amount: 0,
                fullname: '',
                email: '',
                phone: '',
                trip_type: 'Round Trip',
                pax: 1,
                location: '',
                destination: '',
                arrival_datetime: moment(nowDate.setDate(nowDate.getDate() + 1)).format(),
                arrival_flight: '',
                arrival_airline: '',
                departure_datetime: moment(nowDate.setDate(nowDate.getDate() + 1)).format(),
                departure_flight: '',
                departure_airline: '-',
                observations: '-',
                zone_id: 0,
                transport_id: 0,

            },
            transports: [],
            rates: [],
            passengers_numbers: [],
            zones_destination: Zones.zones,
            zones_location: Zones.zones,
            selectedLocation: [],
            dataValidateColor: {
                arrival_flight: {
                    color: DEFAULT_LABEL
                },
                location: {
                    color: DEFAULT_LABEL
                },
                destination: {
                    color: DEFAULT_LABEL
                },
                arrival_datetime: {
                    color: DEFAULT_LABEL
                },
                departure_datetime: {
                    color: DEFAULT_LABEL
                },
                departure_flight: {
                    color: DEFAULT_LABEL
                },
                departure_airline: {
                    color: DEFAULT_LABEL
                }
            }
        };

        this.getTransports();
        this.getRates();
        this.cleanStorage();
    };

    cleanStorage = () => {
        removeStorageVal('reservation');
        removeStorageVal('transportSelected');
        removeStorageVal('zoneSelected');
        removeStorageVal('payCode');
    }

    handleChangeDateArrival = date => {

        this.setState({
            startDateArrival: date,
            startDateDeparture: date
        });

        this.setState(prevState => {
            return {
                new: {
                    ...prevState.new,
                    arrival_datetime: moment(date).format(),
                    departure_datetime: moment(date).format()
                }
            }
        });
    };

    handleChangeDateDeparture = date => {

        this.setState({ startDateDeparture: date });
        this.setState(prevState => {
            return {
                new: {
                    ...prevState.new, departure_datetime: moment(date).format()
                }
            }
        });
    };

    onInputChange = (e) => {

        let value = e.target.value;
        let name = e.target.name;

        this.setState(prevState => {
            return {
                new: {
                    ...prevState.new, [name]: value
                }
            }
        });

        if (name == 'transport_id') {
            let t_selected = this.state.transports.filter((item) => {
                return item.transport_id == value;
            });

            this.setState({
                transportSelected: t_selected[0],
                passengers_numbers: this.getPassengerNumber(parseInt(t_selected[0].capacity))
            });
        }

        if (name == 'trip_type') {
            this.setState(prevState => {
                return {
                    rateSelected: {
                        ...prevState.rateSelected, trip_type: value
                    }
                }
            });
        }
        //console.log("->",value);
    }

    handleChangeAirlineArrival = selectedOption => {

        this.setState(prevState => {
            return {
                new: {
                    ...prevState.new,
                    arrival_airline: selectedOption.value
                }
            }
        });
    };

    handleChangeAirlineDep = selectedOption => {

        this.setState(prevState => {
            return {
                new: {
                    ...prevState.new,
                    departure_airline: selectedOption.value
                }
            }
        });
    };

    onInputChangeLocation = (e) => {

        if (e.value != 'San Jose del Cabo SJD Airport') {
            this.setState({ zones_destination: Zones.zones_restriction });
        } else {
            this.setState({ zones_destination: Zones.zones });
        }

        this.setState(prevState => {
            return {
                new: {
                    ...prevState.new, location: e.value
                }
            }
        });

        this.setState(prevState => {
            return {
                rateSelected: {
                    ...prevState.rateSelected, location: e.value
                }
            }
        });

        return e.value;
    }

    onInputChangeDestination = (e) => {

        this.setState(prevState => {
            return {
                new: {
                    ...prevState.new, destination: e.value
                }
            }
        });

        this.setState(prevState => {
            return {
                rateSelected: {
                    ...prevState.rateSelected, destination: e.value
                }
            }
        });

        return e.value;
    }

    verifiedChange = (e) => {
        this.setState({
            acceptPolices: !this.state.acceptPolices
        });
    };

    onSubmit = (e) => {
        e.preventDefault();

        let _valid = true;

        if (this.state.new.location == '' || this.state.new.destination == '') {
            _valid = false;
            Swal.fire('Verify your location or destination', 'please select a valid location or destination', 'warning');

            this.setState(prevState => {
                return {
                    dataValidateColor: {
                        ...prevState.dataValidateColor,
                        location: { color: ERROR_LABEL },
                        destination: { color: ERROR_LABEL }
                    }
                }
            });
            return;
        } else {
            this.setState(prevState => {
                return {
                    dataValidateColor: {
                        ...prevState.dataValidateColor,
                        location: { color: DEFAULT_LABEL },
                        destination: { color: DEFAULT_LABEL }
                    }
                }
            });
        }

        if (this.state.new.arrival_airline == '') {
            _valid = false;
            Swal.fire("Verify your Airline's Name", "please select your Airline's Name", 'warning');
        }

        if (this.state.new.trip_type == 'Round Trip') {

            if (!moment(this.state.new.departure_datetime).isAfter(this.state.new.arrival_datetime)) {
                Swal.fire('Verify your dates', 'please select valid dates', 'warning');
                _valid = false;
                this.setState(prevState => {
                    return {
                        dataValidateColor: {
                            ...prevState.dataValidateColor,
                            arrival_datetime: { color: ERROR_LABEL },
                            departure_datetime: { color: ERROR_LABEL }
                        }
                    }
                });
                return;
            } else {
                this.setState(prevState => {
                    return {
                        dataValidateColor: {
                            ...prevState.dataValidateColor,
                            arrival_datetime: { color: DEFAULT_LABEL },
                            departure_datetime: { color: DEFAULT_LABEL }
                        }
                    }
                });
            }


            if (this.state.new.departure_flight == '-' || this.state.new.departure_flight == '') {
                Swal.fire('Verify your departure fight number', 'please enter your departure fight number', 'warning');
                _valid = false;
                this.setState(prevState => {
                    return {
                        dataValidateColor: {
                            ...prevState.dataValidateColor,
                            departure_flight: { color: ERROR_LABEL }
                        }
                    }
                });
                return;
            } else {
                this.setState(prevState => {
                    return {
                        dataValidateColor: {
                            ...prevState.dataValidateColor,
                            departure_flight: { color: DEFAULT_LABEL }
                        }
                    }
                });
            }

            if (this.state.new.departure_airline == '-' || this.state.new.departure_airline == '') {
                Swal.fire("Verify your Airline's Name", "please select your Airline's Name", 'warning');
                _valid = false;
                this.setState(prevState => {
                    return {
                        dataValidateColor: {
                            ...prevState.dataValidateColor,
                            departure_airline: { color: ERROR_LABEL }
                        }
                    }
                });
                return;
            } else {
                this.setState(prevState => {
                    return {
                        dataValidateColor: {
                            ...prevState.dataValidateColor,
                            departure_airline: { color: DEFAULT_LABEL }
                        }
                    }
                });
            }

        }

        if (!this.state.acceptPolices) {
            _valid = false;
            this.setState({ valid: false });
            Swal.fire('Verify Reservations Policies', 'please accept reservation polices', 'warning');
            return;
        }

        let _res = ApiGetRateByLocation(this.state.new.location, this.state.new.destination, this.state.new.trip_type, this.state.new.transport_id, this.state.rates)

        this.setState(prevState => {
            return {
                new: {
                    ...prevState.new,
                    amount: _res.amount,
                    zone_id: _res.zone_id
                }
            }
        }, () => {
            console.log(this.state.new);
            if (_valid) {

                storageJson('reservation', this.state.new);
                storageJson('transportSelected', this.state.transportSelected);
                storageVal('zoneSelected', _res.zone_id);
                ApiCreateReservation(this.state.new).then((response) => {
                    console.log("Response Resevation", response);
                    storageVal('code', response.data.results.insertId);
                    window.location.href = '/details';
                }).catch((error) => {
                    console.log("Error Reservation", error);
                });
            }
        });

    }

    getTransports = () => {

        ApiGetTransports().then((response) => {
            this.setState({
                transports: response.data.results,
                transportSelected: response.data.results[0],
                passengers_numbers: this.getPassengerNumber(parseInt(response.data.results[0].capacity))
            });

            this.setState(prevState => {
                return {
                    new: {
                        ...prevState.new,
                        transport_id: response.data.results[0].transport_id
                    }
                }
            })

        }).catch(error => {
            console.log('Error', error);
        });

    };

    getRates = () => {

        ApiRatesReservation().then((response) => {
            console.log("Rates", response);
            this.setState({
                rates: response.data.results
            });

        }).catch(error => {
            console.log('Error', error);
        });

    };

    getPassengerNumber = (max) => {
        let AuxPassengerNum = [];

        for (let index = 0; index < max; index++)
            AuxPassengerNum.push(index + 1);

        return AuxPassengerNum;
    }

    render() {
        return (
            <div id="booking-form" className="booking-form">

                <Header></Header>

                <div className="full" id="breadcrumbs">
                    <div className="grid-container">
                        <div className="contenedor-flex grid-x grid-padding-y">
                        </div>
                    </div>
                </div>

                <div className="grid-container">
                    <div className="grid-x grid-padding-x grid-padding-y">
                        <div className="medium-12 text-center medium-text-left cell">
                            <h1>RESERVATION - Private & Deluxe Transportation</h1>
                        </div>
                    </div>
                </div>

                {
                    (Object.keys(this.state.transportSelected).length != 0) ?
                        <Transport
                            data={this.state.transportSelected}
                            datarate={this.state.rateSelected}
                            rates={this.state.rates}>
                        </Transport>
                        : null
                }
                <div className="grid-container ">
                    <div id="form-traveler" className="grid-x grid-padding-x form-traveler">
                        <div className="small-12 medium-8 large-7 cell">
                            <form onSubmit={this.onSubmit}>
                                <div className="grid-x grid-padding-x">
                                    {
                                        (this.state.formError) ?
                                            <div className="small-12 cell" >
                                                <div className="alert callout">
                                                    <p id="alert" className="big">
                                                        <FontAwesomeIcon icon="exclamation-triangle" />
                                                        There are some errors in your form.
                                                    </p>
                                                </div>
                                            </div> : null
                                    }
                                    <div className="small-12 cell">
                                        <h5>Select Trip Type, Passengers Number, Vehicle, Pickup Location, Destination:</h5>
                                    </div>
                                    <div className="small-12 cell">
                                        <label>SELECT YOUR TRIP TYPE:
                                               <div className="input-group">
                                                <span className="bg-icons input-group-time">
                                                    <FontAwesomeIcon icon="route" />
                                                </span>
                                                <select id="trip_type" value={this.state.new.trip_type} name={'trip_type'} onChange={this.onInputChange}>
                                                    <option value="One Way">One Way</option>
                                                    <option value="Round Trip">Round Trip</option>
                                                </select>
                                            </div>
                                            <label className="form-error fullname-error" data-form-error-for="fullname">Full Name is invalid: 4 character minimum or only text required.</label>
                                        </label>
                                    </div>
                                    <div className="small-6 cell">
                                        <label>SELECT TRANSPORT:
                                               <div className="input-group">
                                                <span className="bg-icons input-group-time">
                                                    <FontAwesomeIcon icon="shuttle-van" />
                                                </span>
                                                <select id="transport_id" value={this.state.new.transport_id} name={'transport_id'} onChange={this.onInputChange}>
                                                    {
                                                        this.state.transports.map((trans, pos) => (
                                                            <option key={pos} value={trans.transport_id}>
                                                                {trans.name}
                                                            </option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                            <label className="form-error fullname-error" data-form-error-for="fullname">Full Name is invalid: 4 character minimum or only text required.</label>
                                        </label>
                                    </div>
                                    <div className="small-6 cell">
                                        <label>SELECT PASSENGERS:
                                               <div className="input-group">
                                                <span className="bg-icons input-group-time">
                                                    <FontAwesomeIcon icon="user-friends" />
                                                </span>
                                                <select id="pax" value={this.state.new.pax} name={'pax'} onChange={this.onInputChange}>
                                                    {
                                                        this.state.passengers_numbers.map((val, pos) => (
                                                            <option key={pos} value={val}>
                                                                {val}
                                                            </option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                            <label className="form-error fullname-error" data-form-error-for="fullname">Full Name is invalid: 4 character minimum or only text required.</label>
                                        </label>
                                    </div>
                                    <div className="small-12 medium-6 cell">
                                        <label><span style={this.state.dataValidateColor.location} id="location">TYPE/SELECT PICKUP LOCATION:</span>
                                            <div className="input-group">
                                                <span className="bg-icons input-group-time">
                                                    <FontAwesomeIcon icon="map-marker-alt" />
                                                </span>

                                                <Select className="select-search"
                                                    options={this.state.zones_location}
                                                    formatGroupLabel={formatGroupLabel}
                                                    onChange={this.onInputChangeLocation}
                                                />

                                            </div>
                                            <label className="form-error fullname-error" data-form-error-for="fullname">Full Name is invalid: 4 character minimum or only text required.</label>
                                        </label>
                                    </div>
                                    <div className="small-12 medium-6 cell">
                                        <label>
                                            <span style={this.state.dataValidateColor.destination} id="destination"> TYPE/SELECT DESTINATION:</span>

                                            <div className="input-group">
                                                <span className="bg-icons input-group-time">
                                                    <FontAwesomeIcon icon="map-marker-alt" />
                                                </span>
                                                <Select className="select-search"
                                                    options={this.state.zones_destination}
                                                    formatGroupLabel={formatGroupLabel}
                                                    onChange={this.onInputChangeDestination}
                                                />
                                            </div>
                                            <label className="form-error fullname-error" data-form-error-for="fullname">Full Name is invalid: 4 character minimum or only text required.</label>
                                        </label>
                                    </div>
                                </div>
                                <hr />
                                <div className="grid-x grid-padding-x uppercase">
                                    <div className="small-12 medium-6 cell">
                                        <h5><i className="fas fa-plane-arrival"></i> Arrival Information:</h5>
                                        <label className=""> <span style={this.state.dataValidateColor.arrival_datetime}>Select Arrival Date Time:</span>
                                            <div className="input-group">
                                                <span className="bg-icons input-group-time"><FontAwesomeIcon icon="calendar-alt" /></span>
                                                <DatePicker
                                                    selected={this.state.startDateArrival}
                                                    onChange={this.handleChangeDateArrival}
                                                    showTimeSelect
                                                    timeIntervals={15}
                                                    className={'input-text'}
                                                    dateFormat="MMMM d, yyyy h:mm aa"
                                                    popperPlacement="top-start"
                                                    minDate={new Date(Date.now() + (3600 * 1000 * 24))}
                                                    popperModifiers={{
                                                        offset: {
                                                            enabled: true,
                                                            offset: "5px, 10px"
                                                        },
                                                        preventOverflow: {
                                                            enabled: true,
                                                            escapeWithReference: false,
                                                            boundariesElement: "viewport"
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </label>
                                        <label className="ArrivalAirlineEnter"> <span>Arrival flight's Number:</span>
                                            <div className="input-group">
                                                <span className="bg-icons input-group-time"><FontAwesomeIcon icon="plane-arrival" /></span>
                                                <input className="input-text" id="arrival_flight" value={this.state.new.arrival_flight}
                                                    name={'arrival_flight'} onChange={this.onInputChange} type="text" placeholder="Type Flight's Number" required autoComplete="off" />
                                            </div>
                                        </label>
                                        <label className="ArrivalAirlineEnter"> Arrival Airline's Name:
                                               <div className="input-group">
                                                <span className="bg-icons input-group-time"><FontAwesomeIcon icon="plane-arrival" /></span>
                                                {/*<input className="input-text" id="arrival_airline" 
                                                value={this.state.new.arrival_airline} name={'arrival_airline'} 
                                                onChange={this.onInputChange} type="text" placeholder="Type Airline Name" required />
                                                */}
                                                <Select
                                                    className="select-search"
                                                    onChange={this.handleChangeAirlineArrival}
                                                    options={this.state.airlines}
                                                />
                                            </div>
                                        </label>
                                    </div>
                                    {
                                        (this.state.new.trip_type == 'Round Trip') ?
                                            <div className="small-12 medium-6 cell departure-info">
                                                <h5><i className="fas fa-plane-departure"></i> Departure Information:</h5>
                                                <label className=""> <span style={this.state.dataValidateColor.departure_datetime}>Select Departure Date Time:</span>
                                                    <div className="input-group">
                                                        <span className="bg-icons input-group-time"><FontAwesomeIcon icon="calendar-alt" /></span>
                                                        <DatePicker
                                                            selected={this.state.startDateDeparture}
                                                            onChange={this.handleChangeDateDeparture}
                                                            showTimeSelect
                                                            className={'input-text'}
                                                            popperPlacement="top-start"
                                                            dateFormat="MMMM d, yyyy h:mm aa"
                                                            minDate={this.state.startDateArrival}
                                                            popperModifiers={{
                                                                offset: {
                                                                    enabled: true,
                                                                    offset: "5px, 10px"
                                                                },
                                                                preventOverflow: {
                                                                    enabled: true,
                                                                    escapeWithReference: false,
                                                                    boundariesElement: "viewport"
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </label>
                                                <label className="DepartFlightEnter"> <span style={this.state.dataValidateColor.departure_flight} >Departure flight Number:</span>
                                                    <div className="input-group">
                                                        <span className="bg-icons input-group-time"><FontAwesomeIcon icon="plane-departure" /> </span>
                                                        <input className="input-text" id="departure_flight" value={this.state.new.departure_flight}
                                                            name={'departure_flight'} onChange={this.onInputChange} type="text" placeholder="Type Flight Number" required autoComplete="off" />
                                                    </div>
                                                </label>
                                                <label className="DepartAirlineEnter"> <span style={this.state.dataValidateColor.departure_airline} >Departure Airline's Name:</span>
                                                    <div className="input-group">
                                                        <span className="bg-icons input-group-time"><FontAwesomeIcon icon="plane-departure" /></span>

                                                        {/*
                                                        <input className="input-text" id="departure_airline" 
                                                        value={this.state.new.departure_airline} name={'departure_airline'} 
                                                        onChange={this.onInputChange} type="text" placeholder="Type Airline Name"
                                                        autoComplete="off" />
                                                        */}

                                                        <Select
                                                            className="select-search"
                                                            onChange={this.handleChangeAirlineDep}
                                                            options={this.state.airlines}
                                                        />
                                                    </div>
                                                </label>
                                            </div>
                                            : null
                                    }
                                </div>
                                <hr />
                                <div className="grid-x grid-padding-x">
                                    <div className="small-12 cell">
                                        <h4><FontAwesomeIcon icon="user" /> Contact / Traveler Information:</h4>
                                        <p>Please fill form with traveler information:</p>
                                        <label className="fullNameEnter"> Enter the traveler's full name:
                                                <div className="input-group">
                                                <span className="bg-icons input-group-time"><FontAwesomeIcon icon="user" /></span>
                                                <input className="input-text" id="fullname" value={this.state.new.fullname} name={'fullname'} onChange={this.onInputChange} type="text" placeholder="type traveler name" required autoComplete="off" />
                                            </div>
                                            <label className="form-error fullname-error" data-form-error-for="fullname">Full Name is invalid: 4 character minimum or only text required.</label>
                                        </label>
                                    </div>
                                    <div className="small-6 medium-6 cell ">
                                        <label className="phoneEnter">Enter the contact's Phone / Cell
                                               <div className="input-group">
                                                <span className="bg-icons input-group-time"><FontAwesomeIcon icon="phone" /></span>
                                                <input className="input-text" id="phone" value={this.state.new.phone} name={'phone'} onChange={this.onInputChange}
                                                    type="text" placeholder="enter example: 15417543010" maxLength="13" aria-describedby="phone" aria-errormessage="phone"
                                                    required autoComplete="off" />
                                            </div>
                                            <label className="form-error" data-form-error-for="phone">Phone number invalid;</label>
                                            <label className="form-error phone-error" data-form-error-for="phone">Invalid: min: 10, max: 14 digits</label>
                                        </label>
                                    </div>
                                    <div className="small-6 medium-6 cell">
                                        <label className="emailEnter"> Enter the contact's <br className="show-for-small-only" /> Email:
                                               <div className="input-group">
                                                <span className="bg-icons input-group-time"><FontAwesomeIcon icon="envelope" /></span>
                                                <input id="email" value={this.state.new.email} name={'email'} onChange={this.onInputChange} type="email" placeholder="type Main Email" />
                                            </div>
                                            <label className="form-error" data-form-error-for="email">Email format is invalid.</label>
                                        </label>
                                    </div>
                                </div>
                                <hr />
                                <div className="grid-x grid-padding-x ">
                                    <div className="medium-12 cell">
                                        <h5><FontAwesomeIcon icon="pen-alt" />Observations:</h5>
                                        <p>Do you have any observations or special requests?</p>
                                        <textarea
                                            id="observations" value={this.state.new.observations} name={'observations'} onChange={this.onInputChange}
                                            type="text" placeholder="type observations" rows="4"></textarea>
                                    </div>
                                </div>
                                <div className="grid-x grid-padding-g grid-padding-y">
                                    <div className="small-12 cell">
                                        <fieldset className={`fieldset ${this.state.valid ? '' : 'fieldset-error'} `}>
                                            <legend><h5>Reservations Policies   </h5></legend>
                                            <input
                                                name={'acceptPolices'}
                                                id="acceptPolices"
                                                type="checkbox"
                                                onChange={this.verifiedChange}
                                                value={this.state.acceptPolices} />

                                            <label> <a href="#" className="policies" data-open="policies">* I have read and accept the terms and conditions *</a> </label>
                                        </fieldset>
                                    </div>
                                </div>
                                <div className="grid-x grid-padding-x hide">
                                    <div className="small-12 medium-12 cell">
                                        <img src="https://aroundcabo.s3-us-west-2.amazonaws.com/booking/paypal-cards.png" />
                                    </div>
                                    <div className="small-12 cell text-right hide">
                                        <p>We need just the 15% of payment for garantize the reservation, the rest payment with cash at arrival or pickup at hotel</p>
                                    </div>
                                </div>
                                <div className="small-12 align-self-bottom cell">
                                    <button id="ibtnConfirm" type="submit" className="submit button warning expanded">
                                        <b className="big">
                                            CONTINUE TO RESERVATION
                                            </b>
                                    </button>
                                </div>
                            </form>
                        </div>
                        {/* ///////////////////  COLUMNA DERECHA */}
                        <Details
                            data={this.state.new}
                            t_selected={this.state.transportSelected}
                            datarate={this.state.rateSelected}
                            rates={this.state.rates}>

                        </Details>

                        {/* ///////////////////  COLUMNA DERECHA */}
                        <div className="reveal large" id="policies" data-reveal>
                            aqui las politicas
                               <button className="close-button" data-close aria-label="Close modal" type="button">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                </div>
                <Footer></Footer>
            </div>
        )
    }
}
