import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Header from './../utils/header';
import Footer from './../utils/footer';
import moment from 'moment';
import { getStorageJson, getStorageVal } from './../../services/storage';

export default class index extends Component {
    constructor() {
        super();

        this.state = {
            reservation: getStorageJson('reservation'),
            transportSelected: getStorageJson('transportSelected'),
            zoneSelected: getStorageVal('zoneSelected'),
            reservation_code: getStorageVal('code'),
            codePay: getStorageVal('payCode'),
            errorCode: false
        }
    }

    componentWillMount = () => {
        if (getStorageJson('reservation') == null) {
            window.location.href = '/';
        }
    }

    render() {
        return (
            <div id="booking-confirmation">
                <Header></Header>
                <div className="grid-container full background-white">
                    <div className="grid-container">
                        <div className="grid-x grid-padding-x grid-padding-y">
                            <div className="small-12 medium-8 medium-offset-1 cell">
                                <h2>Thank You! </h2>
                                <h3><i className="fas fa-check"></i>Reservation Success</h3>
                                <h5 style={{ color: `black` }}>Reservation Code:</h5>
                                <h4>ARCABO-{this.state.reservation_code}</h4>
                                <div className="calout">
                                    {/**
                                    <h5 style={{color:`black`}}>Paypal Payment Confirmation:</h5>
                                    <h4>This transaction will appear in the account statement as Paypal*AROUNDCABO</h4>
                                    <h5 style={{color:`black`}}>Payment Code: <b>{this.state.codePay}</b></h5>
                                     */}
                                </div>
                            </div>
                            <hr />
                            <div className="auto cell">
                                <h5>Amount paid:</h5>
                                <small>Price per Vehicle:</small>
                                <p>
                                    <span className="stat">$ {this.state.reservation.amount} </span>USD
                                    <br />
                                    <small>{this.state.reservation.trip_type}</small>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid-container full">
                    <div className="grid-container">
                        <div className="grid-x grid-padding-x grid-padding-y">
                            <div className="small-12 medium-8 medium-offset-1 cell">
                                <div className="grid-x grid-padding-x align-self-bottom">
                                    <hr />
                                    <div className="small-12 cell">
                                        <h5><i className="fas fa-user"></i> Traveler Information</h5>
                                        <p>Traveler Name: <b>{this.state.reservation.fullname}</b><br />
                                        Phone: <b> {this.state.reservation.phone}</b><br />
                                        Email: <b> {this.state.reservation.email}m</b></p>
                                    </div>
                                    <div className="small-12 cell">
                                        <h5><FontAwesomeIcon icon="shuttle-van" /> Transport and Transfer Details:</h5>
                                        <p>Transport:  <b>{this.state.transportSelected.name}</b><br />
                                        Destination Zone: <b> Zona {this.state.zoneSelected} - {this.state.reservation.destination}</b><br />
                                        Destination Location: <b> {this.state.reservation.location}</b></p>
                                    </div>
                                </div>
                                <div className="grid-x grid-padding-x align-bottom">
                                    <div className="small-6 cell">
                                        <h5><FontAwesomeIcon icon="calendar-alt" /> Arrival Details:</h5>
                                        <p>Arrival Date time: <b> {moment(this.state.reservation.arrival_datetime).format('LLL')}</b><br />
                                            Arrival Airline: <b> {this.state.reservation.arrival_airline}</b><br />
                                            Arrival Flight: <b> {this.state.reservation.arrival_flight}</b></p>
                                    </div>
                                    {
                                        (this.state.reservation.trip_type == 'Round Trip') ?
                                            <div className="small-6 cell">
                                                <h5><FontAwesomeIcon icon="calendar-alt" /> Departure Details:</h5>
                                                <p>Departure Date: <b> {moment(this.state.reservation.departure_datetime).format('LLL')}</b><br />
                                                Departure Airline: <b> {this.state.reservation.departure_airline}</b><br />
                                                Departure Flight: <b> {this.state.reservation.departure_flight}</b></p>
                                            </div> : null
                                    }

                                    <hr />
                                    <div className="small-12 cell">
                                        <h5><FontAwesomeIcon icon="pen-alt" /> Observations</h5>
                                        <p><b>{this.state.reservation.observations}</b></p>
                                        <hr />
                                        <h3><FontAwesomeIcon icon="exclamation-triangle" /> IMPORTANT!</h3>
                                        <p>Please, check your email, if you have not received an email from us please check your spam folder</p>
                                    </div>
                                </div>
                            </div>
                            <hr />
                        </div>
                        <div className="grid-x grid-padding-x flex-container">
                            <div className="className small-12 medium-4 cell medium-offset-1">
                                <a href="/" className="button secondary expanded">GO TO HOME PAGE</a>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer></Footer>
            </div>
        )
    }
}
