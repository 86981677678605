import React from 'react';
import BookingCars from './components/booking';
import ConfirmationCars from './components/confirmation';
import detailsCars from "./components/details";
import { BrowserRouter as Router, Route} from 'react-router-dom';
import { fab } from '@fortawesome/free-brands-svg-icons'
import {
    faPlaneArrival,
    faPlaneDeparture,
    faCalendarAlt,
    faUsers,
    faUser,
    faSuitcase,
    faRoute,
    faExclamationTriangle,
    faShuttleVan,
    faMapMarkedAlt,
    faMapMarkerAlt,
    faPhone,
    faEnvelope,
    faPenAlt,
    faLock,
    faUserFriends,
    faFileInvoiceDollar

} from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(fab, faFileInvoiceDollar, faPlaneArrival, faPlaneDeparture, faCalendarAlt, faUsers, faSuitcase, faRoute, faExclamationTriangle, faShuttleVan, faMapMarkedAlt, faMapMarkerAlt, faUser, faPhone, faEnvelope, faPenAlt, faLock, faUserFriends );
function App() {
    return (
        <Router>
            <div>
                <Route path="/" exact component={ BookingCars } />
                <Route path="/details" component={ detailsCars } />
                <Route path="/confirmation" component={ ConfirmationCars } />
            </div>
        </Router>
    );
}

export default App; 
