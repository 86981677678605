import React, { Component } from 'react';

export default class footer extends Component {
    render() {
        return (
            <footer className="grid-containerfull ">
                <br/>
                <div className="grid-container">
                    <div className="grid-x grid-padding-y grid-padding-x align-self-middle">
                        <div className="small-12 medium-8 cell text-center medium-text-left">
                            <p>Around Cabo Transportation &copy; 2020 - Los Cabos, Baja California Sur, México</p>
                        </div>
                        <div className="small-12 medium-4 cell text-center medium-text-right">
                            <p>Powered by: <a style={{color: '#fff'}} href="https://innversasolutions.com/" target="_blank">Innversa Solutions.</a></p>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}
