import axios from 'axios';
import configs from '../configs/config';
import Zones  from './../../src/places.json';

export const ApiCreateReservation = (newData) => {
    return axios.post(`${configs.URL_API_BASE}/reservations`, newData);
}

export const ApiRatesReservation = () => {
    return axios.get(`${configs.URL_API_BASE}/rates`, null);
}

export const ApiPutReservation = (id, data) => {
    return axios.put(`${configs.URL_API_BASE}/reservations/${id}`, data);
}

export const ApiGetRateByLocation = (location, destination, trip_type, transport_id, rates)=>{
    var response = {};

    if(location != destination){
            
        var zoneSelected = 0;
        var _point = '';

        if(location != 'San Jose del Cabo SJD Airport'){
            _point = location;
        }

        if(destination != 'San Jose del Cabo SJD Airport'){
            _point = destination;
        }

        for (let index = 0; index < Zones.zones.length; index++) {
            const opts = Zones.zones[index].options;

            for (let index = 0; index < opts.length; index++) {
                const element = opts[index];
                if(element.value == _point){
                    zoneSelected = element.zone;
                }
            }

            if(zoneSelected != 0)
                break;
            
        }

        for (let index = 0; index < rates.length; index++) {
            const element = rates[index];

            if(element.zone_id == zoneSelected && element.transport_id == transport_id ){
                var amount_aux = 0;
                switch (trip_type) {
                    case 'One Way':
                            amount_aux = element.oneway;
                        break;
                    case 'Round Trip':
                            amount_aux = element.roundtrip;
                        break;
                }

                response.amount = amount_aux;
                response.zone_id = element.zone_id;
            }

        }

    }else{

        response.amount = 0;
    }

    return response;
}

