export const storageJson = (key, json) => {
    localStorage.setItem(key, JSON.stringify(json));
}

export const storageVal = (key, val) => {
    localStorage.setItem(key, val);
}

export const getStorageJson = (key) => {
    let data;
    try {
        data = JSON.parse(localStorage.getItem(key));
    } catch (error) {
        console.log('Error local storage', error);
    }
    return data;
}

export const getStorageVal = (key) => {
    let data;
    try {
        data = localStorage.getItem(key);
    } catch (error) {
        console.log('Error local storage', error);
    }
    return data;
}

export const removeStorageVal = (key) => {
    localStorage.removeItem(key);
}