import React, { Component, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { ApiGetRateByLocation } from './../../services/booking';

export default class details extends Component {

    // eslint-disable-next-line no-useless-constructor
    constructor() {
        super();

        this.state = {
            showRate: false,
            rate_selected: {
                amount: 0
            }
        };
    };

    componentWillReceiveProps = (nextProps)=>{
        if(nextProps.datarate.location != '' && nextProps.datarate.destination != ''){
            this.state.showRate = true;
            let _ApiAmount = ApiGetRateByLocation(nextProps.datarate.location, nextProps.datarate.destination, nextProps.datarate.trip_type, nextProps.t_selected.transport_id, this.props.rates);
            this.setState(prevState => {
                return { rate_selected: {
                    ...prevState.rate_selected, amount: _ApiAmount.amount
                }}
            });
        }
    }
    render() {
        return (
            <Fragment>
                <div className="small-12 medium-4 large-5 cell">
                    {/* MENSJAE DE MARKETING ---->
                        <div className="small-12 cell">
                            <div className="callout green banner-cancellation-free">
                                <i className="fas fa-check"></i> Free Risk! - Free Cancellations <br />
                                48 hours in advance arrival or pickup at hotel
                            </div>
                        </div>
                    */}
                    <div className="callout">
                        <h5>Your Reservation Summary</h5>
                        <div className="transport-selected show-for-small-only">
                            <h5>Transport Selected:</h5>
                            <div id="img">
                                <img src={this.props.t_selected.image} />
                            </div>
                        </div>
                        <hr/>
                        <p><b>Trip Type:</b> <span className="">{this.props.data.trip_type}</span></p>
                        <p><b>Passengers Number:</b> <span className="pax_num">{this.props.data.pax}</span></p>
                        <p><b>Transport Selected:</b> <span className="transport">{this.props.t_selected.name}</span></p>
                        <p><b>Pickup Location:</b> <span className="transport">{this.props.data.location}</span></p>
                        <p><b>Destination:</b> <span className="transport">{this.props.data.destination}</span></p>
                        <hr />
                        <h5>Arrival Information:</h5>
                        <div className="grid-x">
                            <div className="small-12 cell">
                            <p><b>Date / Time:</b> <br /> <span className=" arrival_date">{moment(this.props.data.arrival_datetime).format('MMMM Do YYYY, h:mm:ss a')}</span></p>
                            </div>
                            <div className="small-6 cell">
                                <p className="flightNumberArrival"><b>Flight Number: </b><br /><span className="flight-numberArrival">{this.props.data.arrival_flight}</span></p>
                            </div>
                            <div className="small-6 cell">
                                <p className="airlineNameArrival"><b>Airline Name: </b><br /><span className="airline-nameArrival">{this.props.data.arrival_airline}</span></p>
                            </div>
                        </div>

                        {
                            (this.props.data.trip_type != 'One Way') ? 
                            <Fragment>
                                <hr />
                                <h5>Departure Information:</h5>
                                <div className="grid-x">
                                    <div className="small-12 cell">
                                        <p><b>Date / Time:</b><br /><span className="departure_date">{moment(this.props.data.departure_datetime).format('MMMM Do YYYY, h:mm:ss a')}</span></p>
                                    </div>
                                    <div className="small-6 cell">
                                        <p className="flightNumberDeparture"><b>Flight Number: </b><br /><span className="flight-numberDeparture">{this.props.data.departure_flight}</span></p>
                                    </div>
                                    <div className="small-6 cell">
                                        <p className="airlineNameDeparture"><b>Airline Name: </b><br /><span className="airline-nameDeparture">{this.props.data.departure_airline}</span></p>
                                    </div>
                                </div>
                            </Fragment>
                            : null
                        }
                        
                        <hr/>
                        <h5>Contact / Traveler Information:</h5>
                        <div id="fullnameTraveler">
                            <p><b>Full name: </b> <span className="fullname">{this.props.data.fullname}</span></p>
                        </div>
                        <div id="phoneTraveler">
                            <p><b>Phone: </b><span className="phone">{this.props.data.phone}</span></p>
                        </div>
                        <div id="emailTraveler">
                            <p><b>E-mail: </b><span className="email">{this.props.data.email}</span></p>
                        </div>
                        <hr />
                        <h5>Ground Trasportation to Pay:</h5>
                        <div className="grid-x">
                            <div className="small-6 cell">
                                <h4>TOTAL:</h4>
                            </div>
                            <div className="small-6 cell text-right price-total">
                                <h4> <b>$ {this.state.rate_selected.amount}</b> USD</h4>
                            </div>
                            {/*}
                            <div className="small-8 cell">
                                <p><b>Payment 1 (15% online On agreement):</b><br />
                                    <small>Free Cancellations must be made 48 hours  in advance</small>
                                </p>
                            </div>
                            <div className="small-4 cell text-right">
                                <p className=""> <b> $15 USD</b> </p>
                            </div>
                            <div className="small-8 cell">
                                <p><b>Remaining Balance (payment in cash)</b><br />
                                    <small>(pay upon arrival or pick up at the hotel):</small></p>
                            </div>
                            <div className="small-4 cell text-right">
                                <p className=""> <b> $15 USD </b> </p>
                            </div>
                            {*/}
                            
                        </div>
                        <div className="grid-x">
                        <hr/>
                            <h4>Payment on Arrival: Credit Card o Cash Payment</h4>
                            <div className="small-12 cell">
                                <img src={process.env.PUBLIC_URL + '/izettle-payment.jpg'} />
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
