import React, { Component } from 'react';
import PaypalExpressBtn from 'react-paypal-express-checkout';
import Swal from 'sweetalert2';

import Header from './../utils/header';
import Footer from './../utils/footer';
import moment from 'moment';
import { getStorageJson, getStorageVal, storageVal, removeStorageVal } from './../../services/storage';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ApiPutReservation } from './../../services/booking'



export default class index extends Component {

    constructor() {
        super();

        this.state = {
            reservation: getStorageJson('reservation'),
            transportSelected: getStorageJson('transportSelected'),
            zoneSelected: getStorageVal('zoneSelected'),
            reservation_code: getStorageVal('code'),
            errorCode: false
        }

    }

    componentWillMount = () => {
        if (getStorageJson('reservation') == null) {
            window.location.href = '/';
        }
    }

    createReservation = () => {
        var data_put = {
            "payment_code": 'Cash',
            "reservation_code": "ARCABO-" + this.state.reservation_code,
            "paid_id": "Complete"
        };

        ApiPutReservation(this.state.reservation_code, data_put).then((resp) => {
            //console.log('Payment Update Resp',resp);
            storageVal('payCode', 'Cash');
            window.location.href = '/confirmation';
        });
    }

    render() {
        const onSuccess = (payment) => {
            // Congratulation, it came here means everything's fine!
            //console.log("The payment was succeeded!", payment);
            // You can bind the "payment" object's value to your state or props or whatever here, please see below for sample returned data
            if (payment.paid) {

                var data_put = {
                    "payment_code": payment.paymentID,
                    "reservation_code": "ARCABO-" + this.state.reservation_code,
                    "paid_id": "Complete"
                };

                ApiPutReservation(this.state.reservation_code, data_put).then((resp) => {
                    //console.log('Payment Update Resp',resp);
                    storageVal('payCode', payment.paymentID);
                    window.location.href = '/confirmation';
                });
            } else {
                Swal.fire('Error Payment Transaction', 'please verify this problem with our administrator', 'error');
            }
        }

        const onCancel = (data) => {
            // User pressed "cancel" or close Paypal's popup!
            //console.log('The payment was cancelled!', data);
            // You can bind the "data" object's value to your state or props or whatever here, please see below for sample returned data
        }

        const onError = (err) => {
            // The main Paypal's script cannot be loaded or somethings block the loading of that script!
            //console.log("Error!", err);
            // Because the Paypal's main script is loaded asynchronously from "https://www.paypalobjects.com/api/checkout.js"
            Swal.fire('Error Payment Transaction', 'please verify this problem with our administrator', 'error');
            // => sometimes it may take about 0.5 second for everything to get set, or for the button to appear
        }

        let env = 'sandbox'; // you can set here to 'production' for production
        let currency = 'USD'; // or you can set this value from your props or state
        let total = this.state.reservation.amount; // same as above, this is the total amount (based on currency) to be paid by using Paypal express checkout
        // Document on Paypal's currency code: https://developer.paypal.com/docs/classic/api/currency_codes/

        const client = {
            sandbox: 'AQ21K141yTlo9GfUH05cL7Bh08oHaAlV554scBw4Z3Ue9pMkbKrBael0UmoswsPogk5F5_xa8M93y6y1',
            production: 'YOUR-PRODUCTION-APP-ID',
        }

        return (
            <div id="booking-payment" className="booking-form">
                <Header></Header>

                <div className="grid-container ">
                    <div className="grid-x grid-padding-x grid-padding-y">

                        {
                            (this.state.errorCode) ?
                                <div className="medium-8 medium-offset-2 cell">
                                    <div data-abide-error className="alert callout small-12 medium-8 medium-offset-2 cell">
                                        <p id="alert" className="big"><FontAwesomeIcon icon="exclamation-triangle" /> Error Mensaje</p>
                                    </div>
                                </div>
                                : null
                        }

                        <div id="infoPayment" className="small-12 medium-8 medium-offset-2 cell">
                            <div className="grid-x grid-padding-x align-self-bottom">
                                <div className="small-12 cell">
                                    <h1>Reservation Details</h1>
                                    <h2>ARCABO-{this.state.reservation_code}</h2>
                                </div>
                                <div className="small-12 cell text-right">
                                    <hr />
                                    <div className="grid-x">
                                        <div className="small-12 medium-6 cell">
                                            <img className="img-fluid" src={this.state.transportSelected.image} alt={"transportation vehicle"} />
                                        </div>
                                        <div className="small-12 medium-6 cell">
                                            <small>Price per Vehicle:</small>
                                            <p>
                                                <span className="stat">$ {this.state.reservation.amount} USD</span>
                                                USD
                                                <br />
                                            </p>
                                            <p>Transport: <b>{this.state.transportSelected.name}</b><br />
                                                Destination: <b> {this.state.reservation.destination}</b><br />
                                                Zone Destination: <b> Zone {this.state.zoneSelected}</b><br />
                                                Type Trip: <b> {this.state.reservation.trip_type}</b>
                                            </p>
                                        </div>
                                    </div>
                                    <hr />
                                </div>
                                <hr />
                                <div className="small-12 cell">
                                    <p>Traveler Name: <b>{this.state.reservation.fullname}</b><br />
                                        Phone: <b> {this.state.reservation.phone}</b><br />
                                        Email: <b> {this.state.reservation.email}</b></p>
                                </div>
                            </div>
                            <div className="grid-x grid-padding-x grid-padding-y align-bottom">
                                <div className="small-6 cell">
                                    <p>Arrival Date: <b>{moment(this.state.reservation.arrival_datetime).format('LLL')}</b><br />
                                        Arrival Airline: <b> {this.state.reservation.arrival_airline}</b><br />
                                        Arrival Flight: <b> {this.state.reservation.arrival_flight}</b></p>
                                </div>
                                {
                                    (this.state.reservation.trip_type == 'Round Trip') ?
                                        <div className="small-6 cell">
                                            <p>Departure Date time: <b>{moment(this.state.reservation.departure_datetime).format('LLL')}</b><br />
                                            Departure Airline: <b> {this.state.reservation.departure_airline}</b><br />
                                            Departure Flight: <b> {this.state.reservation.departure_flight}</b></p>
                                        </div> : null
                                }
                                <div className="small-12 cell">
                                    {/** 
                                    <h5>Paypal Payment:</h5>
                                    <PaypalExpressBtn env={env} client={client} currency={currency} total={total} onError={onError} onSuccess={onSuccess} onCancel={onCancel} />
                                    */}
                                    <button id="ibtnConfirm" onClick={this.createReservation} className="submit button warning expanded">
                                        <b className="big">
                                            FINISH RESERVATION
                                        </b>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer></Footer>
            </div>
        )
    }
}
