import React, { Component, Fragment } from 'react';
import { ApiGetRateByLocation } from './../../services/booking';

export default class transport extends Component {
    // eslint-disable-next-line no-useless-constructor
    constructor() {
        super();

        this.state = {
            showRate: false,
            rate_selected: {
                amount: 0
            }
        };
    };

    componentWillReceiveProps = (nextProps)=>{
        if(nextProps.datarate.location != '' && nextProps.datarate.destination != ''){
            this.state.showRate = true;
            let _ApiAmount = ApiGetRateByLocation(nextProps.datarate.location, nextProps.datarate.destination, nextProps.datarate.trip_type, nextProps.data.transport_id, this.props.rates);
            this.setState(prevState => {
                return { rate_selected: {
                    ...prevState.rate_selected, amount: _ApiAmount.amount
                }}
            });
        }
    }

    render() {
        return (
            <Fragment>
                <div className="grid-container">
                    <div className="grid-x grid-padding-y details align-middle">
                        <div className="small-12 medium-4 cell flex-container align-center">
                            <img className="" src={this.props.data.image} alt="Around Cabo Deluxe Transportation" />
                        </div>
                        <div className="small-12 medium-4 cell text-center medium-text-left">
                            <h3 className="transporteName">{this.props.data.name}</h3>
                            <p><b>Capacity: </b>
                                <span className="max-pax">{this.props.data.capacity}</span>
                            </p>
                            <h6> <b>{this.props.data.description}</b> </h6>
                            <p><b>Amenities:</b> {this.props.data.amenities}</p>
                        </div>
                        {
                            (this.state.showRate) ? 
                            <div className="small-12 medium-4 cell small-only-text-center medium-text-right">
                                <div className="grid-x grid-padding-x">
                                    <div className="radio-buttons small-12 large-12 cell align-rates">
                                        <small><b> <em>Cost Per Vehicle:</em></b></small>
                                        <div className="oneway small-12 large-12 cell">
                                            {this.props.datarate.trip_type}
                                            <br/>
                                            <small className="rate-regular">$<span className="OneWayRegular">{this.state.rate_selected.amount + 10}</span>USD</small><br />
                                            $<span className="OneWayPromo">{this.state.rate_selected.amount}</span>USD <br />
                                            <small>All Taxes included</small>
                                        </div>
                                    </div>
                                    <div className="small-12 cell text-center medium-text-right green">
                                        <p>Free Risk! - Free Cancellations</p>
                                    </div>
                                </div>
                            </div>
                            : null
                        }
                        
                    </div>
                </div>
            </Fragment>
        )
    }
}
